@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

::root {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    scroll-snap-type: proximity;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y proximity;
}

body::-webkit-scrollbar{
    display: none;
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 16px;

    @media (min-width: 1960px) {
        font-size: calc(14px + 0.4vw);
    }
    @media (max-width: 1440px) {
        font-size: 14px;
    }
    @media (max-width: 768px) {
        font-size: 13px;
    }
    @media (max-width: 576px) {
        font-size: 12px;
    }
}

li {
    list-style: none;
}
a {
    text-decoration: none;
}
